import React, { Component } from "react";
import "./styles/bestpractices.css";
import { eventcategorieslisting } from "../shared/constants";

export class BestpracticesContent extends Component {
  render() {
    return (
      <div>
        <section className="termPrivacybody" id="event-categories" style={{ paddingBottom: 0 }}>
          <div className="row termPrivacybody_content">
            <div className="col-11 col-lg-8">
              <div className="termPrivacybody_content_text mt-n5">
                <div className="text">
                  <div className="image d-none d-md-block">
                    <img
                      src="images/best_practices_event_categories_web.png"
                      alt="Financial Budgeting"
                      height={364}
                      style={{ width: "100%" }}
                      className="imageResize"
                    />
                  </div>
                  <div className="image d-md-none mt-3 mb-3">
                    <img
                      src="images/best_practices_event_categories_mobile.png"
                      alt="Financial Budgeting"
                      height={195}
                      style={{ width: "100%" }}
                      className="mobile-imageResize"
                    />
                  </div>
                  <h3 className="padHeader">Event & Activity Categories</h3>
                  <p>
                    As mentioned in the Time Budgeting section, Lifeli
                    makes you event and activity categories which work similar
                    to financial budget categories when financial budgeting. The
                    10 event category budgets are as follows:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="termPrivacybody mt-n5 pt-0"
          // style={{ backgroundColor: "red" }}
        >
          <div className="event_content row">
            <div className="col-12">
              <div className="scrolling-wrapper card-container">
                {eventcategorieslisting.map((item, index) => (
                  <div className={`card m-1 card-item`} key={item.title}>
                    <div
                      className="card_illustration"
                      style={{ backgroundColor: item.color }}
                    >
                      <img src={item.image} alt="Illustration" />
                    </div>
                    <div className="card_text">
                      <h4 className="card_text_header header-3">
                        {item.title}
                      </h4>
                      <div className="card-description">
                        <p
                          style={{
                            whiteSpace: "pre-line",
                            // textAlign: "justify",
                            fontSize: 13,
                            paddingBottom: "5px"
                          }}
                        >
                          {item.description}
                        </p>
                        <div className="flex-wrap d-flex mb-3  button-container">
                          {item.categories.map((el) => (
                            <span
                              key={el.title}
                              className="categoryitembutton"
                              style={{
                                marginBottom: "3px",
                                color: item.color,
                                border: `1px solid ${item.color}`,
                                backgroundColor: item.colorShade,
                                // marginTop: "5px"
                              }}
                            >
                              {el.title}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="termPrivacybody pt-1" id="30mins" style={{ paddingBottom: 0 }}>
          <div className="row termPrivacybody_content">
            <div className="col-11 col-lg-8">
              <div className="termPrivacybody_content_text mt-n5">
                <div className="text">
                  <div className="image d-none d-md-block">
                    <img
                      src="images/best_practices_30_mins_web.png"
                      alt="Financial Budgeting"
                      height={364}
                      style={{ width: "100%" }}
                      className="imageResize"
                    />
                  </div>
                  <div className="image d-md-none mt-3 mb-3">
                    <img
                      src="images/best_practices_30_mins_mobile.png"
                      alt="Financial Budgeting"
                      height={195}
                      style={{ width: "100%" }}
                      className="mobile-imageResize"
                    />
                  </div>
                  <h3>30 Min Increments</h3>
                  <p>
                    To get the most out of the app, we suggest that you block
                    and track your activities in 30 min increments. By doing
                    this, you get the benefit of optimizing all aspects of your
                    life without the stress of macro managing yourself.
                  </p>
                  <p>
                    Tracking in 30 min increments means that you round up your
                    activities to the nearest half an hour or hour (:30 or
                    1:00). As long as you spent 15+ min on an activity, you can
                    track it as the 30 min segment. If you did two activities
                    for 12 & 18mins, you track the 18mins activity as the 30 min
                    segment.
                  </p>
                  <p>
                    Doing this allows you to accommodate the following:
                    <br />
                    <b>- Lateness,</b> which happens to the best of us for up to
                    15mins.
                    <br />
                    <b>- Prep time,</b> for example dressing up to go for a run.
                    <br />
                    <b>- Transition time,</b> switching from one task to another
                    without stressing out.
                    <br />
                    <b>- Distraction time,</b> picking a call from a wrong
                    number or 5 mins of Instagram.
                    <br />
                    <b>- Break Time,</b> a 15min break is suggested for every
                    hour of work.
                  </p>
                  <p>
                    {" "}
                    We thought about it all, 30 min increment works best. But if
                    you ever need to track 15 min activities, the app allows you
                    to do just that. You are Welcome 😁
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default BestpracticesContent;
