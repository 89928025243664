import React, { Component } from "react";
import TermsNav from "../components/termsnav.component";
import FooterSection from "../components/footer.component";
import CustomIntroSection from "../components/customintro.component";
import BudgetCategoriesContent from "../components/budgetcategories.component";

export class BudgetCategoriesPage extends Component {
  render() {
    return (
      <div>
        <TermsNav />
        <CustomIntroSection intro="Time Budget Categories" />
        <BudgetCategoriesContent />
        <FooterSection />
      </div>
    );
  }
}

export default BudgetCategoriesPage;
