import React, { Component } from "react";
// import Nav from "../components/nav.component";
import FooterSection from "../components/footer.component";
import TermsNav from "../components/termsnav.component";
import CustomIntroSection from "../components/customintro.component";
import QuickstartBodySection from "../components/quickstart.component";

class Quickstart extends Component {
  render() {
    return (
      <div>
        <TermsNav />
        <CustomIntroSection intro="Quick Start Guide for Lifeli App" />
        <QuickstartBodySection />
        <FooterSection />
      </div>
    );
  }
}

export default Quickstart;
