import React from "react";
import PrivacyPolicyPage from "./view/privacy.page";
import IndexPage from "./view/index.page";
import TermsPage from "./view/terms.page";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Page404 from "./components/page404.component";
import Quickstart from "./view/quickstart.page";
import Budgetingprinciples from "./view/budgetingprinciples.page";
import BestpracticesPage from "./view/bestpractices.page";
import BudgetCategoriesPage from "./view/budgetcategories.page";

const Route = require("react-router-dom").Route;

function App() {
  return (
    //<IndexPage />
    // <TermsPage />
    <Router>
      <Switch>
        <Route path="/" exact strict component={IndexPage} />
        <Route path="/terms" exact strict component={TermsPage} />
        <Route path="/privacy" exact strict component={PrivacyPolicyPage} />
        <Route path="/quickstart" exact strict component={Quickstart} />
        <Route
          path="/budgeting-principles"
          exact
          strict
          component={Budgetingprinciples}
        />
        <Route
          path="/best-practices"
          exact
          strict
          component={BestpracticesPage}
        />
        <Route
          path="/budget-categories"
          exact
          strict
          component={BudgetCategoriesPage}
        />
        <Route path="*" exact={true} component={Page404} />
      </Switch>
    </Router>
  );
}

export default App;
