export const eventcategorieslisting = [
  {
    title: "Work & Business",
    code: "work-and-business",
    image: "svgs/work&business.svg",
    benefit: "Income \nGeneration",
    hours: "40+",
    color: "#F74F5A",
    colorShade: "#FDDCDE",
    categories: [
      { title: "Work", summary: "On all Work Activies" },
      { title: "Business", summary: "On all Business Activities" }
    ],
    description:
      "These activities generate value at work or income for your business. Discussing work over lunch, researching for a new project, and replying to emails are few examples."
  },
  {
    title: "Career Development",
    code: "career-development",
    image: "svgs/career.svg",
    color: "#30D2D0",
    colorShade: "#D6F6F6",
    benefit: "Value \nCreation",
    hours: "5+",
    categories: [
      {
        title: "Educate",
        summary: "Acquiring knowledge for Work"
      }
    ],
    description:
      "These activities help you acquire knowledge specifically for Work or/and Business. Taking a course or watching videos to learn a new skill for your job are few examples."
  },
  {
    title: "Personal Development",
    categories: [
      { title: "Learn", summary: "Learning something new" },
      { title: "Plan", summary: "On all sort of planning activities" },
      { title: "Finance", summary: "Managing your finances" },
      { title: "Volunteer", summary: "Helping someone in need" }
    ],
    image: "svgs/pers.svg",
    color: "#F3A636",
    colorShade: "#FDEDD7",
    code: "personal-development",
    benefit: "Emotional \n& Mental Energy ",
    hours: "5+",
    description:
      "These activities enable you to learn something new not associated with Work or/and Business. Planning, learning how to bake, listening to an audiobook, personal accounting e.t.c."
  },
  {
    title: "Spiritual",
    color: "#455DBE",
    colorShade: "#DADFF2",
    categories: [
      { title: "Devotion", summary: "On all religious activities" },
      { title: "Meditate", summary: "On all non religious activities" }
    ],
    image: "svgs/spiritually1.svg",
    code: "spiritual",
    benefit: "Spiritual \nEnergy",
    hours: "7+",
    description:
      "This category is used for activities related to your religious or/and spiritual practices. Attending church or spiritual retreat, reading a religious text and meditation are few examples."
  },
  {
    title: "Fitness",
    categories: [
      { title: "Run", summary: "Running" },
      { title: "Bike", summary: "Cycling" },
      { title: "Swim", summary: "Swimming" },
      { title: "Stretch", summary: "Stretching" },
      { title: "Training", summary: "On all other fitness activities" }
    ],
    image: "svgs/fitness.svg",
    color: "#313F48",
    colorShade: "#D6D9DA",
    code: "fitness",
    benefit: "Physical \nEnergy",
    hours: "7+",
    description:
      "This category is used for any exercise-related activity. Walking, running, swimming, biking, playing tennis, boxing, kayaking, e.t.c. Whatever sports you like. Aim to break a sweat."
  },
  {
    title: "Relationship",
    categories: [
      { title: "Connect", summary: "Connecting with friends & family" },
      { title: "Love", summary: "With your significant other" },
      { title: "Nurture", summary: "Taking care of your kid(s)" },
      { title: "Pet", summary: "Caring & playing with your pet" }
    ],
    image: "svgs/relationship.svg",
    colorShade: "#D4EFE3",
    color: "#27B072",
    code: "relationship",
    benefit: "Companionship \n& Intimacy ",
    hours: "20+",
    description:
      "This category is used for activities spent with people or animals, solely for the purpose of bonding. Friends, family, significant other, kids or pets. Aim to build meaningful connections."
  },
  {
    title: "Self Care",
    image: "svgs/selfcare.svg",
    colorShade: "#E6F6D6",
    color: "#81D134",
    categories: [
      { title: "Groom", summary: "On grooming & bathroom duties" },
      { title: "Meal", summary: "Feeding yourself" },
      { title: "Faff", summary: "In between activities (indecision)" },
      {
        title: "Medical",
        summary: "On all sort of medical activities"
      },
      { title: "Leisure", summary: "Relaxing and enjoying yourself" },
      { title: "Social", summary: "On social media sites - non-work" },
      { title: "Vacation", summary: "Exploring the world and time off" }
    ],
    code: "self-care",
    benefit: "Good \nHealth",
    hours: "20+",
    description:
      "This category is used as a kind of catch-all for anything in relation to relaxing, rejuvenating or just having fun. Grooming, vacations, doctor visits, spa treatment, and meals are few examples."
  },
  {
    title: "Sleep",
    categories: [{ title: "sleep", summary: "Sleeping" }],
    color: "#36C0F9",
    colorShade: "#D7F2FE",
    image: "svgs/sleep.svg",
    code: "sleep",
    benefit: "Recovery \n& Recharge ",
    hours: "49+",
    description:
      "This category is used for the ultimate form of recuperation. We can’t overemphasize the importance of sleep and naps when you need it. This is premium fuel for all other activities."
  },
  {
    title: "Errand",
    categories: [
      { title: "Cook", summary: "Preparing a meal" },
      { title: "Clean", summary: "Cleaning anything" },
      { title: "Shop", summary: "Purchasing anything" }
    ],
    color: "#B89560",
    colorShade: "#F1EADF",
    image: "svgs/errand.svg",
    code: "errand",
    benefit: "Support Core \nActivities",
    hours: "5+",
    description:
      "This category is used for tasks performed which are not related to any of the others but necessary for daily life. Cooking, cleaning, shopping, e.t.c. Work towards outsourcing these activities."
  },

  {
    code: "travel",
    title: "Travel",
    image: "svgs/travel.svg",
    color: "#C169FC",
    colorShade: "#F3E1FE",
    categories: [
      { title: "Drive", summary: "Spent in a moving car" },
      { title: "Train", summary: "Spent in a moving train" },
      { title: "Flight", summary: "Spent in air transport" },
      { title: "Ferry", summary: "Spent in sea transport" }
    ],
    benefit: "Commuting\n & Transportation",
    hours: "10+",
    description:
      "This category is used for getting from one point to another as long as it does not involve exercise. Exercise falls under Fitness. Rides in cars, ferries, trains, airplanes, scooters, e.t.c."
  }
];
