import React, { Component } from "react";
import CustomIntroSection from "../components/customintro.component";
import FooterSection from "../components/footer.component";
import BestpracticesContent from "../components/bestpracticesbody.component";
import "../App.css";
import TermsNav from "../components/termsnav.component";

export class BestpracticesPage extends Component {
  render() {
    return (
      <div>
        <TermsNav />
        <CustomIntroSection intro="Best Practices" />
        <BestpracticesContent />
        <FooterSection />
      </div>
    );
  }
}

export default BestpracticesPage;
