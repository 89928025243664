import React, { Component } from "react";
import "./styles/budgetcategories.css";
import "./styles/bestpractices.css";
import { eventcategorieslisting } from "../shared/constants";

export class BudgetCategoriesContent extends Component {
  render() {
    return (
      <section className="termPrivacybody">
        <div className="row termPrivacybody_content">
          <div className="col-11 col-lg-8">
            <div className="termPrivacybody_content_text">
              <div className="text d-none d-md-block">
                <section className="d-flex pl-5 pr-5">
                  <h5 className="table-content">Event Categories</h5>
                  <h5 className="table-content">Activity Categories</h5>
                  <h5 className="table-content3">Summary</h5>
                </section>
                {eventcategorieslisting.map((e) => (
                  <section
                    className="d-flex table-item-container pl-5 pr-5 pt-2 pb-4 m-1"
                    key={e.title}
                  >
                    <p className="table-content mt-0">{e.title}</p>
                    <div className="d-flex flex-column" style={{ flex: 2.5 }}>
                      {e.categories.map((el) => (
                        <div className="d-flex mb-2" key={el.title}>
                          <div style={{ flex: 1 }}>
                            <span
                              className="categoryitembutton"
                              style={{
                                height: "30px",
                                color: e.color,
                                border: `1px solid ${e.color}`,
                                backgroundColor: e.colorShade
                              }}
                            >
                              {el.title}
                            </span>
                          </div>
                          <div style={{ flex: 1.5 }}>
                            <p className="p-0 m-0">{el.summary}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                ))}
              </div>
              <div className="text d-md-none mt-n5">
                <p className="pt-0">
                  Below are the time budget categories. They consist of the
                  event category, activity category, and the summary of what you
                  should use them to track.
                </p>
                {eventcategorieslisting.map((e) => (
                  <div className="mt-3" key={e.title}>
                    <h5>{e.title}</h5>
                    <section className="table-item-container p-3">
                      {e.categories.map((el) => (
                        <div className="d-flex mb-3 justify-content-between">
                          <span
                            className="categoryitembutton d-flex align-items-center"
                            style={{
                              height: "30px",
                              color: e.color,
                              border: `1px solid ${e.color}`,
                              backgroundColor: e.colorShade
                            }}
                          >
                            {el.title}
                          </span>
                          <span style={{fontSize: "0.9em"}}>{el.summary}</span>
                        </div>
                      ))}
                    </section>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BudgetCategoriesContent;
