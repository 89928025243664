import React from 'react';

const ScoreSection = () => {
    return (
      <section className="score">
        <div className="score_content row">
          <div className="col-12">
            <h3 className="header-3 text-center">Life Score</h3>
            <p className="bodyText text-center">
              The Life Score<sup>TM</sup> is a rating index algorithm created by
              aggregating research findings from leading health and well-being
              experts about what a healthy lifestyle should look like.
            </p>
            <div className="image">
              <img src="svgs/score_scale.svg" alt="Life score" />
            </div>
          </div>
        </div>
      </section>
    );
}

export default ScoreSection;
