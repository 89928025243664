import React from "react";
import "./styles/quickstart.css";

const QuickstartBodySection = () => {
  return (
    <section className="termPrivacybody">
      <div className="row termPrivacybody_content">
        <div className="col-11 col-lg-10">
          <div className="termPrivacybody_content_text">
            <h3>What is Lifeli?</h3>
            <div className="text">
              <p className="content-text">
                Lifeli is an activity tracking app that uses the principles
                of 
                
                <a href="/budgeting-principles#time-blocking" target="_blank" rel="noopener noreferrer"> 
               <span className="bold-link"> time blocking </span></a>
               
               {/* <span className="underline">time blocking</span>  */}
                & 
               <a href="/budgeting-principles#time-tracking" target="_blank" rel="noopener noreferrer"> 
               <span className="bold-link"> time tracking </span></a>
                {/* <span className="underline">time tracking</span>  */}
                
                 to help you{" "}
                <span className="underline">plan your activities</span>,{" "}
                <span className="underline">measure progress</span>, and{" "}
                <span className="underline">accomplish your goals</span> while
                providing you with{" "}
                <span className="underline">
                  insights into your current lifestyle
                </span>
                .
              </p>
            </div>
          </div>
          <div className="termPrivacybody_content_text">
            <h3>Before You Begin</h3>
            <div className="text">
              <p className="content-text">
                Note: Using Lifeli requires you to develop the{" "}
                <span className="underline">art of mindfulness</span> and{" "}
                <span className="underline">build the habit</span> of planning
                (blocking) and tracking your progress. Behavior change usually
                doesn’t happen overnight so please be kind to yourself. Most new
                habits require an average of 66days to form but using
                lifeli wouldn’t take that long. Give it 30days of
                consistent effort.
              </p>
            </div>
          </div>
          <div className="termPrivacybody_content_text">
            <h3>Creating your first event</h3>
            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 1: </h6>
                &nbsp;Tap on&nbsp;
                <i
                  className="fas fa-plus fa-xs plus-container"
                  style={{ color: "#ffffff" }}
                ></i>
                &nbsp; to add an event
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/step1_web.png"
                  alt="how it works"
                  className="quarterimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/step1_web.png"
                  alt="how it works"
                  className="quarterimg-mobile"
                />
              </div>
            </div>
            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 2: </h6>
                &nbsp;Select the appropriate&nbsp;
                <a href="/best-practices#event-categories" target="_blank" rel="noopener noreferrer"> 
               <span className="bold-link">Event and Activity Category</span></a>
                {/* <span className="underline">Event and Activity Category</span> */}
                &nbsp;for the activity you want to block or track.
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/step2_web.png"
                  alt="how it works"
                  className="fullimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/step2_web.png"
                  alt="how it works"
                  className="fullimg-mobile"
                />
              </div>
            </div>
            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 3: </h6>
                &nbsp;(Optional)&nbsp;
                <span className="underline">Add a short description</span>
                &nbsp;of that specific activity. For example Lunch, Instagram,
                Content Marketing e.t.c. Whatever you need to remind you of what
                you were doing specifically during that time interval.
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/step3_web.png"
                  alt="how it works"
                  style={{ width: "90%", height: "173px" }}
                  className="quarterimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/step3_mobile.png"
                  alt="how it works"
                  className="quarterimg-mobile"
                />
              </div>
            </div>
            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 4: </h6>
                &nbsp;Select the Start and End Time. Note: We block and track
                in&nbsp;
               <a href="/best-practices#30mins" target="_blank" rel="noopener noreferrer"> 
               <span className="bold-link">30mins increments</span></a>.
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/step4_web.png"
                  alt="Step 4"
                  style={{ width: "90%" }}
                  className="fullimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/step4_mobile.png"
                  alt="Step 4"
                  className="fullimg-mobile"
                />
              </div>
            </div>
            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 5: </h6>
                &nbsp;(Optional) If you are blocking time for a future event,
                you can set a reminder.&nbsp;
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/step5_web.png"
                  alt="Step 5"
                  style={{ width: "90%" }}
                  className="quarterimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/step5_mobile.png"
                  alt="Step 5"
                  className="quarterimg-mobile"
                />
              </div>
            </div>
            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 6: </h6>
                &nbsp;Click&nbsp;
                <img
                  src="svgs/check-bg.svg"
                  alt="Check"
                  style={{
                    display: "inline-block"
                  }}
                />
                &nbsp; to save
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/step6_web.png"
                  alt="Step 6"
                  className="quarterimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/step6_web.png"
                  alt="Step 6"
                  className="quarterimg-mobile"
                />
              </div>
            </div>
            <p>That’s it. Congratulations on creating your first event.</p>
          </div>
          <div className="hrLine" />
          <div className="termPrivacybody_content_text mt-5">
            <h3>Reviewing your insights</h3>
            <div className="text">
              <p className="content-text">
                You can use Lifeli to block and track all kinds of
                activities. It’s up to you. The more activities you track, the
                more insightful the lifestyle analytics become.
              </p>
              <p className="steplabelline mb-3">
                <h6>Step 1: </h6>
                &nbsp;Tap on&nbsp;
                <img
                  src="svgs/reports.svg"
                  alt="Step 1"
                  style={{
                    display: "inline-block"
                  }}
                />
                &nbsp; to view insights
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/review_step1_web.png"
                  alt="Step 1"
                  className="quarterimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/review_step1_web.png"
                  alt="Step 6"
                  className="quarterimg-mobile"
                />
              </div>
            </div>
            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 2: </h6>
                &nbsp;Tap <span className="dummy-button">Charts</span> to see
                the details of tracked activities. The pie chart shows you
                details by event categories while the bar chart shows you
                details of activity categories.&nbsp;
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/review_step2_web.png"
                  alt="Step 2"
                  style={{ width: "622px" }}
                  className="fullimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/review_step2_web.png"
                  alt="Step 2"
                  style={{ height: "299px" }}
                  className="fullimg-mobile"
                />
              </div>
            </div>

            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 3: </h6>
                &nbsp;Tap <span className="dummy-button">Habit Tracker</span> to
                see your activity count per day & Habit Tracker. The lower the
                number, the better because this means you did not switch tasks
                too often. The Habit Tracker tracks your habit via activities
                performed daily. Try not to break the chain for activities
                required to be done daily for goal achievement.&nbsp;
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/review_step3_web.png"
                  alt="Step 3"
                  style={{ width: "622px" }}
                  className="fullimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/review_step3_web.png"
                  alt="Step 3"
                  style={{ height: "299px" }}
                  className="fullimg-mobile"
                />
              </div>
            </div>

            <div className="text">
              <p className="steplabelline mb-3">
                <h6>Step 4: </h6>
                &nbsp;Tap <span className="dummy-button">Life Score</span> to
                see your Life Score, a work-life balance rating index based on
                the duration and kind of activities performed in a week. Note:
                You need to track 168hrs of activities in one week to view your
                Life Score. Tap the Score to view the score key metrics&nbsp;
              </p>
              <div className="image d-none d-md-block">
                <img
                  src="images/review_step4_web.png"
                  alt="Step 4"
                  style={{ width: "622px" }}
                  className="fullimg-web"
                />
              </div>
              <div className="image d-md-none">
                <img
                  src="images/review_step4_web.png"
                  alt="Step 4"
                  style={{ height: "299px" }}
                  className="fullimg-mobile"
                />
              </div>
            </div>
            <div className="mt-5">
              <h3>Experiencing any challenge?</h3>
              <p className="help-text">
                Get help directly from our founder{" "}
                <a href="mailto:olu@liferithms.com">olu@liferithms.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuickstartBodySection;
