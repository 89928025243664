import React, { Component } from "react";
import "./styles/quickstart.css";

const styles = {
  mobileImageStyle: {
    height: "195px",
    width: "100%"
  },
  webImageStyle: {
    height: "364px",
    width: "100%"
  }
};

export class BudgetBodySection extends Component {
  render() {
    return (
      <section className="termPrivacybody">
        <div className="row termPrivacybody_content">
          <div className="col-11 col-lg-8">
            <div className="termPrivacybody_content_text mt-n5">
              <div className="text">
                <p>
                  Lifeli uses the same principles used by billions of
                  people worldwide to manage their finances. It is the one and
                  only trusted way to manage your money. We make use of the
                  principle of budgeting.
                </p>
              </div>
              <div className="text" id="financial-budgeting">
                <div className="image d-none d-md-block">
                  <img
                    src="images/financial_budgeting_web.png"
                    alt="Financial Budgeting"
                    style={styles.webImageStyle}
                    className="imageResize"
                  />
                </div>
                <div className="image d-md-none mt-3 mb-3">
                  <img
                    src="images/financial_budgeting_mobile.png"
                    alt="Financial Budgeting"
                    style={styles.mobileImageStyle}
                    className="mobile-imageResize"
                  />
                </div>
                <h3>Financial Budgeting</h3>

                <p>
                  Budgeting is often used when there are few resources available
                  that can be used to accomplish multiple things. In money
                  management, budgeting is used because most of us have a
                  limited amount and it can be used to purchase anything.
                  <br />
                  <br />
                  We budget our money by first allocating it to the most
                  important things. In most cases, that is rent or/and food.
                  What we have left gets spent on the less important things like
                  entertainment until we have none left except we decide to save
                  some.
                </p>
              </div>

              <div className="text" id="time-budgeting">
                <div className="image d-none d-md-block">
                  <img
                    src="images/time_budgeting_web.png"
                    alt="Time Budgeting"
                    style={styles.webImageStyle}
                    className="imageResize"
                  />
                </div>
                <div className="image d-md-none mt-3 mb-3">
                  <img
                    src="images/time_budgeting_mobile.png"
                    alt="Time Budgeting"
                    style={styles.mobileImageStyle}
                    className="mobile-imageResize"
                  />
                </div>
                <h3>Time Budgeting</h3>

                <p>
                  Within the Lifeli app, we applied the same financial
                  budgeting principles to create time budgeting. After several
                  years of research, we realized its importance in{" "}
                  <span className="underline">life management</span>,{" "}
                  <span className="underline">time management</span> and{" "}
                  <span className="underline">energy management</span>.
                  <br />
                  <br />
                  There are two activities that make budgeting possible:
                  planning and tracking. Planning has to do with the allocation
                  of resources while tracking focuses on the measurement of used
                  resources.
                  <br />
                  <br />
                  We plan by allocating our resources to specific tasks/goals
                  then we track to measure if we lived up to our allocation
                  plan. We repeat this plan and track cycle until we achieve the
                  desired result. Budgeting is a simple and effective way to
                  manage limited resources.
                </p>
              </div>

              <div className="text" id="time-blocking">
                <div className="image d-none d-md-block">
                  <img
                    src="images/time_blocking_web.png"
                    alt="Time Blocking"
                    style={styles.webImageStyle}
                    className="imageResize"
                  />
                </div>
                <div className="image d-md-none mt-3 mb-3">
                  <img
                    src="images/time_blocking_mobile.png"
                    alt="Time Blocking"
                    style={styles.mobileImageStyle}
                    className="mobile-imageResize"
                  />
                </div>
                <h3>Time Blocking</h3>

                <p>
                  Time blocking is the act of allocating a specific amount of
                  time to a specific kind of activity. This is similar to
                  allocating money to a particular item. For blocking to be
                  effective, we need a categorization to group similar
                  activities together.
                  <br />
                  <br />
                  In financial budgeting, we have financial budgeting categories
                  like rent, entertainment, food, utilities e.t.c. None existed
                  for time so we created{" "}

                  <a href="/budget-categories" target="_blank" rel="noopener noreferrer"> 
               <span className="bold-link"> Time Budget Categories</span></a> to
                  make time budgeting possible. We
                  <span className="underline"> plan our activities</span> in{" "}

                  <a href="/best-practices#30mins" target="_blank" rel="noopener noreferrer"> 
               <span className="bold-link"> 30min increments</span></a>.
                  {/* <span className="underline"> 30min increments</span>. */}
                </p>
              </div>

              <div className="text" id="time-tracking">
                <div className="image d-none d-md-block">
                  <img
                    src="images/time_tracking_web.png"
                    alt="Time tracking"
                    style={styles.webImageStyle}
                    className="imageResize"
                  />
                </div>
                <div className="image d-md-none mt-3 mb-3">
                  <img
                    src="images/time_tracking_mobile.png"
                    alt="Time tracking"
                    style={styles.mobileImageStyle}
                    className="mobile-imageResize"
                  />
                </div>
                <h3>Time Tracking</h3>
                <p>
                  Time Tracking has to do with measuring the amount of time
                  spent on a specific activity. Basically it is expense tracking
                  for your time. Within the app, time tracking feeds into our
                  lifestyle data analytics which{" "}
                  <span className="underline">measures progress</span> and{" "}
                  <span className="underline">
                    provides lifestyle insights.
                  </span>
                  <br />
                  <br />
                  In addition to this, the app’s pie chart measures time spent
                  in all ten event categories, while the bar chart measures time
                  spent in specific activity categories. It also feeds data to
                  the activity counter, habit tracker and Life Score. We track
                  in 
                  <a href="/best-practices#30mins" target="_blank" rel="noopener noreferrer"> 
               <span className="bold-link"> 30min increments</span></a>.
                  {/* <span className="underline">30min increments.</span> */}
                </p>
              </div>
              <div className="text">
                <h3 className="mt-4">Summary</h3>
                <p>
                  Blocking & Tracking is what makes time budgeting possible.
                  Download the Lifeli App to start using the same
                  principles you use to manage your money to manage your life,
                  time and energy while{" "}
                  <span className="underline">accomplishing your goals.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BudgetBodySection;
