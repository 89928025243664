import React, { Component } from "react";
import TermsNav from "../components/termsnav.component";
import CustomIntroSection from "../components/customintro.component";
import FooterSection from "../components/footer.component";
import BudgetBodySection from "../components/budgetbody.component";

export class Budgetingprinciples extends Component {
  render() {
    return (
      <div>
        <TermsNav />
        <CustomIntroSection intro="Principles of Budgeting" />
        <BudgetBodySection />
        <FooterSection />
      </div>
    );
  }
}

export default Budgetingprinciples;
